import React from 'react'

import { CardMedia, Card, CardHeader, CardActionArea, CardContent, Typography } from '@material-ui/core'
import Layout from 'components/layout'
import SEO from 'components/seo'
import Video1 from 'videos/Come uscire fuori dalle case su Adopt Me.mp4'
import Video2 from 'videos/Come volare con le macchine su Adopt Me.mp4'

const SecondPage = () => (
  <Layout>
    <SEO title="Tutorial" />
    <h1>Tutorial</h1>
    <p>Benvenuti alla pagina tutorial.</p>
    <Card raised>
      <CardHeader />

      <CardActionArea>
        <CardMedia component="video" image={Video1} controls />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            Come uscire fuori dalle case su Adopt Me
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActionArea>
        <CardMedia component="video" image={Video2} controls />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            Come volare con le macchine su Adopt Me
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  </Layout>
)

export default SecondPage
